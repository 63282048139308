<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-row>
        <b-col lg="6">
          <b-card title="Filtre transações">
            <b-row>
              <b-col md="2">
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="0"
                >
                  Pendente
                </b-form-radio>
              </b-col>
              <b-col md="2">
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="1"
                >
                  Pago
                </b-form-radio>
              </b-col>
              <b-col md="2">
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="2"
                >
                  Cancelado
                </b-form-radio>
              </b-col>
              <b-col md="2">
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="3"
                >
                  Processando
                </b-form-radio>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

         <b-col>
          <b-card title="Data inicial">
            <template>
             <b-form-datepicker
              v-model="from"
              locale="pt-br"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
             />
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Data final">
            <template>
              <b-form-datepicker
                v-model="to"
                locale="pt-br"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
              />
            </template>
          </b-card>
        </b-col>
      </b-row>
      <withdraws-list
        :orders="withdraw_orders.data"
        :context-actions="'admin'"
        @emitSidebar="openTransactionSidebar"
      />
      <nav aria-label="...">
        <ul class="pagination">
          <li
            v-if="withdraw_orders.paginatorInfo.currentPage - 1 > 0"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(-1)"
            >Anterior</span>
          </li>
          <li class="page-item active">
            <span class="page-link">
              {{ withdraw_orders.paginatorInfo.currentPage }}
            </span>
          </li>
          <li
            v-if="withdraw_orders.paginatorInfo.currentPage < withdraw_orders.paginatorInfo.lastPage"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(+1)"
            >Proxima</span>
          </li>
        </ul>
      </nav>
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
    <edit-transaction-sidebar
      ref="transactionSidebar"
      :transaction="transactionObj"
      @refreshOrders="refreshOrders()"
    />

  </div>

</template>

<script>
import Datepicker from 'vuejs-datepicker'

import {
  BCard, BCol, BRow, BCardText, BLink, BFormRadio,BFormDatepicker
} from 'bootstrap-vue'
import GeneralGroupsList from '@/components/groups/GeneralGroupsList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import WithdrawsList from '@/components/transactions/WithdrawsList.vue'
import EditTransactionSidebar from '@/components/transactions/EditTransactionSidebar.vue'

export default {
  components: {
    BCard,
    BFormDatepicker,
    BCardText,
    BLink,
    GeneralGroupsList,
    underscore: _,
    LogoLoaderAnimation,
    WithdrawsList,
    EditTransactionSidebar,
    BFormRadio,
    BCol,
    BRow,

  },
  data() {
    return {
      from: new Date().toISOString().split('T')[0],
      to: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      searchQuery: '',
      currentPage: 1,
      queryResult: null,
      transactionObj: null,
      transactionStatus: 0,
      statusName: ['PENDING', 'CONFIRMED', 'CANCELED','PROCESSING'],
      pageToSearch: 1,
    }
  },
  apollo: {
    withdraw_orders: {
      query: gql`query($status:String, $offset:Int, $from: Mixed, $to: Mixed){
      withdraw_orders: 
        orders(first:500,order_type:"WITHDRAW",status:$status ,page:$offset,  where: {column:CREATED_AT, operator:GTE, value: $from , AND:{column:CREATED_AT, operator:LTE, value: $to}}){
          data{
            id,
            order_type,
            user_id,
            amount,
            status,
            created_at,
            updated_at,
            target_bank{
              bank_name,
              owner_name,
              account,
              agency
            },
            tags{
              key,
              value
            },
            provider{
              code
            }
          },
          paginatorInfo{
            currentPage,
            lastPage
          }
        }
      }`,
      client: 'cashioClient',
      variables() {
        return {
          status: this.statusName[this.transactionStatus],
          from: this.from,
          to: this.to,
          offset: this.pageToSearch,

        }
      },
    },
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  created() {
    // this.searchUser()
  },
  methods: {
    openTransactionSidebar(e) {
      this.transactionObj = e
      this.$refs.transactionSidebar.toogleV()
    },
    paginate(eV) {
      if(this.pageToSearch == 1){
        this.pageToSearch = 2;

      }else{
        this.pageToSearch = this.pageToSearch + eV;
      }
    },
  },
}
</script>

<style>

</style>
